@tailwind base;
@tailwind components;
@tailwind utilities;

html {
}

body {
  background: rgb(162, 163, 163);
}

#root {
}
